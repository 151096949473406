@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");

.manage-cohort-title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #1d3969;
    margin-top: 32pt;
    margin-bottom: 10px;
}

.manage-cohort-subtitle {
    margin-top: 0;
    font-family: "Nunito";
    margin-bottom: 30px;
}

.cohort-panel-group {
    display: flex;
    justify-content: space-between;
    flex: 1 1 0px;
}

.panel-subtext {
    margin-top: 30px;
    font-family: "Nunito";
    font-weight: 400;
}

.inactive-cohort-program-group {
    margin-bottom: -16px;
}

.inactive-cohort-list {
    font-family: "Nunito";
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}

.active-cohort-list p {
    font-family: "Nunito";
    font-size: 14px;
    font-weight: 400;
    margin-bottom: -3px;
}

.program-expansion {
    position: relative;
}

.expansion-arrow {
    position: absolute;
    right: 8px;
    top: -5px;
    height: 32px;
    width: 32px;
    filter: grayscale(1) brightness(70%);
    cursor: pointer;
}

.expand-animation {
    animation-name: expand;
    animation-duration: 400ms;
    animation-fill-mode: forwards;
}

@keyframes expand {
    to {
        transform: rotate(90deg);
    }
}

.collapse-animation {
    animation-name: collapse;
    animation-duration: 400ms;
    animation-fill-mode: forwards;
}

@keyframes collapse {
    from {
        transform: rotate(90deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.cohort-section {
    display: flex;
    flex-direction: column;
    font-family: "Nunito";
}

.cohort-name-section {
    position: relative;
    margin-top: -5px;
    margin-bottom: 25px;
}

.program-manager-section {
    margin-bottom: 35px;
}

.term-start-section {
    width: 294px
}

#acp-program-selection-dropdown-menu span {
    margin-top: 6px;
    font-size: 12px;
    color: #606060;
}

.cohort-creation-section {
    width: 32.4%;
    display: flex;
    flex-direction: column;
}

#cohort-creation-form {
    height: 100%
}

.cohort-creation-form {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.cohort-creation-form .MuiInputLabel-shrink {
    font-size: 16px;
}

.cohort-creation-form label {
    color: #000000;
}

.cohort-creation-form label {
    color: #000000;
}

.create-cohort-button {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 114px;
}

.create-cohort-button button {
    height: 35px;
    width: 149px;
    border-radius: 50px;
    border: 2px solid #1D3969;
    background-color: #FFFF;
    color: #1D3969;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    transition: box-shadow 300ms;
}

.create-cohort-button button:hover {
    cursor: pointer;
    box-shadow: 2px 3px 6px #606060;
}

.MuiOutlinedInput-root fieldset {
    border-color: black;
}

.assistance-panel-header {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: "Nunito";
    font-weight: 600;
}

.assistance-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.assistance-body p {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: -10px;
}

.question-icon {
    margin-right: 12px;
}

.submit-request button {
    transition: box-shadow 300ms;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.submit-request button:hover {
    cursor: pointer;
    box-shadow: 2px 3px 6px #606060;
}

#submit-request {
    height: 35px;
    width: 149px;
    border-radius: 50px;
    border: 2px solid #000000;
    background-color: #FFFF;
    color: #000000;
}