body {
  margin: 0;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(to right,#1D3868 1.5%, white 1.5%); */
}

/* Open sans font class*/
.open-sans {
  font-family: 'open sans' !important;
}

.nunito {
  font-family: 'Nunito' !important;
}

.raleway {
  font-family: 'Raleway' !important;
}

/* font weights*/
.fwt-400 {
  font-weight: 400;
}

.fwt-500 {
  font-weight: 500;
}

.fwt-600 {
  font-weight: 600;
}

.fwt-700 {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Line Heights */
.lineHt-normal {
  line-height: 1
}

.lineHt-sm {
  line-height: 1.2
}

.lineHt-sm-1 {
  line-height: 1.5
}

.lineHt-sm-2 {
  line-height: 2
}

.lineHt-md {
  line-height: 2.5
}

.lineHt-lg {
  line-height: 3
}

/*font sizes*/
.text-sm {
  font-size: 12px;
}

.text-normal {
  font-size: 14px;
}

.text-md {
  font-size: 16px;
}

.text-lg {
  font-size: 18px;
}

.text-xlg {
  font-size: 22px;
}

.text-jumbo {
  font-size: 32px;
}

/* Text Clamp */
.text-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden
}

/* alignment */
.v-align {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}

/* text transformation */
.italics {
  font-style: italic;
}

.hide {
  display: none !important;
}

.mark-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/*Cursors */
.pointer {
  cursor: pointer;
}

.unselectable {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

/*Borders */
.border-top {
  border-top: 1px solid lightgrey;
}

/* MARGINS */
.mt-1 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mt-4 {
  margin-top: 20px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 15px;
}

/* animations */
.fade {
  animation: fadeItem;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

@keyframes fadeItem {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0;
  }
}

.fade-07-start {
  animation: fadeItem07Start;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes fadeItem07Start {
  0% {
    opacity: 0.7;
  }

  25% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.3;
  }

  75% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}