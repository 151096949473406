@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
body {
  margin: 0;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(to right,#1D3868 1.5%, white 1.5%); */
}

/* Open sans font class*/
.open-sans {
  font-family: 'open sans' !important;
}

.nunito {
  font-family: 'Nunito' !important;
}

.raleway {
  font-family: 'Raleway' !important;
}

/* font weights*/
.fwt-400 {
  font-weight: 400;
}

.fwt-500 {
  font-weight: 500;
}

.fwt-600 {
  font-weight: 600;
}

.fwt-700 {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Line Heights */
.lineHt-normal {
  line-height: 1
}

.lineHt-sm {
  line-height: 1.2
}

.lineHt-sm-1 {
  line-height: 1.5
}

.lineHt-sm-2 {
  line-height: 2
}

.lineHt-md {
  line-height: 2.5
}

.lineHt-lg {
  line-height: 3
}

/*font sizes*/
.text-sm {
  font-size: 12px;
}

.text-normal {
  font-size: 14px;
}

.text-md {
  font-size: 16px;
}

.text-lg {
  font-size: 18px;
}

.text-xlg {
  font-size: 22px;
}

.text-jumbo {
  font-size: 32px;
}

/* Text Clamp */
.text-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden
}

/* alignment */
.v-align {
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/* text transformation */
.italics {
  font-style: italic;
}

.hide {
  display: none !important;
}

.mark-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/*Cursors */
.pointer {
  cursor: pointer;
}

.unselectable {
  -webkit-user-select: none;
  /* Safari */
  /* Firefox */
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

/*Borders */
.border-top {
  border-top: 1px solid lightgrey;
}

/* MARGINS */
.mt-1 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mt-4 {
  margin-top: 20px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 15px;
}

/* animations */
.fade {
  -webkit-animation: fadeItem;
          animation: fadeItem;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

@-webkit-keyframes fadeItem {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeItem {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0;
  }
}

.fade-07-start {
  -webkit-animation: fadeItem07Start;
          animation: fadeItem07Start;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

@-webkit-keyframes fadeItem07Start {
  0% {
    opacity: 0.7;
  }

  25% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.3;
  }

  75% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeItem07Start {
  0% {
    opacity: 0.7;
  }

  25% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.3;
  }

  75% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@-webkit-keyframes online-join {
  from {
    -webkit-transform: scaleY(.98) scaleX(.98);
            transform: scaleY(.98) scaleX(.98);
    opacity: .7;
    -webkit-filter: blur(3px);
            filter: blur(3px);

  }

  to {
    -webkit-transform: scaleY(1.3) scaleX(1.05);
            transform: scaleY(1.3) scaleX(1.05);
    opacity: .8;
    -webkit-filter: blur(3px);
            filter: blur(3px);

  }
}


@keyframes online-join {
  from {
    -webkit-transform: scaleY(.98) scaleX(.98);
            transform: scaleY(.98) scaleX(.98);
    opacity: .7;
    -webkit-filter: blur(3px);
            filter: blur(3px);

  }

  to {
    -webkit-transform: scaleY(1.3) scaleX(1.05);
            transform: scaleY(1.3) scaleX(1.05);
    opacity: .8;
    -webkit-filter: blur(3px);
            filter: blur(3px);

  }
}

.animated-join-now-btn {
  position: relative;
  z-index: 2;

}

.animated-join-now-btn:before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(360deg, #14606B  6%,  #ACFFE1 100%);
  -webkit-transform: translate3d(0px, 0px, 0) scale(.95);
          transform: translate3d(0px, 0px, 0) scale(.95);
  opacity: var(1);
  border-radius: inherit;
  -webkit-animation: online-join .8s alternate ease-out;
          animation: online-join .8s alternate ease-out;
  -webkit-animation-iteration-count: 8;
          animation-iteration-count: 8
}

/* 
* Prevents issues when the parent creates a 
* stacking context. (For example, using the transform
* property )
*/
.animated-join-now-btn::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  border-radius: inherit;
}


.animated-online-indicator {
  position: relative;
  z-index: 2;
  display:flex;
  align-items: center;

}


.animated-online-indicator:before {
  content: "";
  z-index: -1;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(-5deg, #09e172 -29%, #4ca581 133% );

  -webkit-transform: translate3d(0px, 0px, 0) scale(1);

          transform: translate3d(0px, 0px, 0) scale(1);
  -webkit-filter: blur(1px);
          filter: blur(1px);
  opacity: 0;
  border-radius: 100vh;
  -webkit-animation: online-indicator .8s alternate ease-out;
          animation: online-indicator .8s alternate ease-out;
  -webkit-animation-iteration-count: 8;
          animation-iteration-count: 8;
  width: 8px;
  height: 8px;
}

/* 
* Prevents issues when the parent creates a 
* stacking context. (For example, using the transform
* property )
*/
.animated-online-indicator::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  border-radius: inherit;
}
      


@-webkit-keyframes online-indicator {
  from {
    -webkit-transform: scale(.9);
            transform: scale(.9);
    opacity: .8;

  }

  to {
   -webkit-transform: scale(1.4);
           transform: scale(1.4);
   opacity: .9;
  }
}
      


@keyframes online-indicator {
  from {
    -webkit-transform: scale(.9);
            transform: scale(.9);
    opacity: .8;

  }

  to {
   -webkit-transform: scale(1.4);
           transform: scale(1.4);
   opacity: .9;
  }
}
.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-11-9 14:25:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="0.75em"]::before {
    content: "Small";
  }
  
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1em"]::before {
    content: "Normal";
  }
  
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1.5em"]::before {
    content: "Large";
  }
  
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="2.5em"]::before {
    content: "Huge";
  }
  
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="0.75em"]::before {
    content: "Small";
    font-size: 0.75em !important;
  }
  
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1em"]::before {
    content: "Normal";
    font-size: 1em !important;
  }
  
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]::before {
    content: "Large";
    font-size: 1.5em !important;
  }
  
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="2.5em"]::before {
    content: "Huge";
    font-size: 2.5em !important;
  }  
.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;


    line-height: 1.125em;
    z-index: 1;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}

.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    text-decoration: none;
    padding-bottom: 1em;
}

.react-calendar__month-view__weekdays__weekday abbr {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;


}



.react-calendar__month-view__weekNumbers .react-calendar__tile {


    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}

/* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
} */

.react-calendar__month-view__days__day--neighboringMonth {
    color: #9C9C9C;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    position: relative;
    max-width: 100%;
    width: 100%;
    background: none;
    text-align: center;


    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding: 10px;
    box-sizing: border-box;

}

.react-calendar__tile:hover {
    background-color: #F3F3F3;
    border-radius: 5px;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}

/* .react-calendar__tile:enabled:hover, */
/* .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
    border-radius: 5px;

} */

.react-calendar__tile--now {
    background: rgba(99, 194, 157, .3);
    /* color: white; */
    border-radius: 5px;
}

/* .react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
} */

/* .react-calendar__tile--hasActive {
    background: #76baff;

} */

/* .react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
} */
.react-calendar__navigation__prev2-button {
    display: none;
}

.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__tile--active {
    color: white;

    position: relative;
    z-index: 2
}

.react-calendar__tile--active abbr {
    color: white;
    z-index: 2
}

.react-calendar__tile--active abbr:after {
    content: '';
    background-color: #14606B;
    width: 40px;
    height: 40px;
    border-radius: 100vh;
    position: absolute;
    top: 1px;
    left: 5px;
    z-index: -1
}

.react-calendar__tile--active abbr {}


/* .react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #1087ff;
} */

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.cohort-list-wrapper {
  border: 1px solid #000000;
  border-radius: 10px;
}

body {
  font-family: "Open Sans";
}

.manage-cohort-table-cell {
  margin-right: 15px;

  font-size: 14px;
  position: relative;
}

.manage-cohort-table-row {
  min-height: 50px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 22% 22% 12% 12% 12% 12% 12%;
  /* padding: 12px; */
  align-items: center;
  padding-left: 16px;
  background-color: white;
}

.add-member-table-row {
  min-height: 44px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 20% 20% 15% 15% 15% 13% 2%;

  align-items: center;
  padding-left: 16px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pointer {
  cursor: pointer;
}

.manage-cohort-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #404040;
  margin-bottom: 0;
}

.cohort-name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #1d3969;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cohort-member-count {
  color: #404040;
  font-size: 18px;

  margin-left: 5px;
}

.permission-overview-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  color: #404040;
  margin-top: 30px;
  margin-bottom: 10px;
}

.permission-overview-table-container {
  max-width: 1200px;
  border: 1px solid #000000;
  border-radius: 10px;
  margin-bottom: 30px;
  overflow: hidden;

}

.permission-overview-table-row {
  display: grid;
  grid-template-columns: 12% 21% 28% 38%;
  align-items: center;
  padding: 8px 0 8px 16px;
  border-bottom: 1px solid #d9d9d9;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  background-color: white;
  overflow: hidden;


}

.add-members-button {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 158.9%;
  color: #1d3969;
  background: #ffffff;
  border: 2px solid #1D3969;
  border-radius: 50px;
  /* padding: 8px 12px; */
  max-height: 36px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 149px;
  height: 35px;
  text-decoration: none;
  text-align: center;
}

.manage-cohort-table-container {
  border: 1px solid #000000;
  border-radius: 10px;
  min-width: 620px;
  overflow: hidden;

}

.manage-cohort-table-container--modal {
  border: 1px solid #000000;
  border-radius: 10px;
  min-width: 620px;


}
.manage-cohort-title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #1d3969;
    margin-top: 32pt;
    margin-bottom: 10px;
}

.manage-cohort-subtitle {
    margin-top: 0;
    font-family: "Nunito";
    margin-bottom: 30px;
}

.cohort-panel-group {
    display: flex;
    justify-content: space-between;
    flex: 1 1;
}

.panel-subtext {
    margin-top: 30px;
    font-family: "Nunito";
    font-weight: 400;
}

.inactive-cohort-program-group {
    margin-bottom: -16px;
}

.inactive-cohort-list {
    font-family: "Nunito";
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}

.active-cohort-list p {
    font-family: "Nunito";
    font-size: 14px;
    font-weight: 400;
    margin-bottom: -3px;
}

.program-expansion {
    position: relative;
}

.expansion-arrow {
    position: absolute;
    right: 8px;
    top: -5px;
    height: 32px;
    width: 32px;
    -webkit-filter: grayscale(1) brightness(70%);
            filter: grayscale(1) brightness(70%);
    cursor: pointer;
}

.expand-animation {
    -webkit-animation-name: expand;
            animation-name: expand;
    -webkit-animation-duration: 400ms;
            animation-duration: 400ms;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes expand {
    to {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }
}

@keyframes expand {
    to {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }
}

.collapse-animation {
    -webkit-animation-name: collapse;
            animation-name: collapse;
    -webkit-animation-duration: 400ms;
            animation-duration: 400ms;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes collapse {
    from {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }

    to {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

@keyframes collapse {
    from {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }

    to {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

.cohort-section {
    display: flex;
    flex-direction: column;
    font-family: "Nunito";
}

.cohort-name-section {
    position: relative;
    margin-top: -5px;
    margin-bottom: 25px;
}

.program-manager-section {
    margin-bottom: 35px;
}

.term-start-section {
    width: 294px
}

#acp-program-selection-dropdown-menu span {
    margin-top: 6px;
    font-size: 12px;
    color: #606060;
}

.cohort-creation-section {
    width: 32.4%;
    display: flex;
    flex-direction: column;
}

#cohort-creation-form {
    height: 100%
}

.cohort-creation-form {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.cohort-creation-form .MuiInputLabel-shrink {
    font-size: 16px;
}

.cohort-creation-form label {
    color: #000000;
}

.cohort-creation-form label {
    color: #000000;
}

.create-cohort-button {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 114px;
}

.create-cohort-button button {
    height: 35px;
    width: 149px;
    border-radius: 50px;
    border: 2px solid #1D3969;
    background-color: #FFFF;
    color: #1D3969;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    transition: box-shadow 300ms;
}

.create-cohort-button button:hover {
    cursor: pointer;
    box-shadow: 2px 3px 6px #606060;
}

.MuiOutlinedInput-root fieldset {
    border-color: black;
}

.assistance-panel-header {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: "Nunito";
    font-weight: 600;
}

.assistance-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.assistance-body p {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: -10px;
}

.question-icon {
    margin-right: 12px;
}

.submit-request button {
    transition: box-shadow 300ms;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.submit-request button:hover {
    cursor: pointer;
    box-shadow: 2px 3px 6px #606060;
}

#submit-request {
    height: 35px;
    width: 149px;
    border-radius: 50px;
    border: 2px solid #000000;
    background-color: #FFFF;
    color: #000000;
}
