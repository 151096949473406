@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");

.cohort-list-wrapper {
  border: 1px solid #000000;
  border-radius: 10px;
}

body {
  font-family: "Open Sans";
}

.manage-cohort-table-cell {
  margin-right: 15px;

  font-size: 14px;
  position: relative;
}

.manage-cohort-table-row {
  min-height: 50px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 22% 22% 12% 12% 12% 12% 12%;
  /* padding: 12px; */
  align-items: center;
  padding-left: 16px;
  background-color: white;
}

.add-member-table-row {
  min-height: 44px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 20% 20% 15% 15% 15% 13% 2%;

  align-items: center;
  padding-left: 16px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pointer {
  cursor: pointer;
}

.manage-cohort-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #404040;
  margin-bottom: 0;
}

.cohort-name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #1d3969;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cohort-member-count {
  color: #404040;
  font-size: 18px;

  margin-left: 5px;
}

.permission-overview-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  color: #404040;
  margin-top: 30px;
  margin-bottom: 10px;
}

.permission-overview-table-container {
  max-width: 1200px;
  border: 1px solid #000000;
  border-radius: 10px;
  margin-bottom: 30px;
  overflow: hidden;

}

.permission-overview-table-row {
  display: grid;
  grid-template-columns: 12% 21% 28% 38%;
  align-items: center;
  padding: 8px 0 8px 16px;
  border-bottom: 1px solid #d9d9d9;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  background-color: white;
  overflow: hidden;


}

.add-members-button {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 158.9%;
  color: #1d3969;
  background: #ffffff;
  border: 2px solid #1D3969;
  border-radius: 50px;
  /* padding: 8px 12px; */
  max-height: 36px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 149px;
  height: 35px;
  text-decoration: none;
  text-align: center;
}

.manage-cohort-table-container {
  border: 1px solid #000000;
  border-radius: 10px;
  min-width: 620px;
  overflow: hidden;

}

.manage-cohort-table-container--modal {
  border: 1px solid #000000;
  border-radius: 10px;
  min-width: 620px;


}