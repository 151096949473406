.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@keyframes online-join {
  from {
    transform: scaleY(.98) scaleX(.98);
    opacity: .7;
    filter: blur(3px);

  }

  to {
    transform: scaleY(1.3) scaleX(1.05);
    opacity: .8;
    filter: blur(3px);

  }
}

.animated-join-now-btn {
  position: relative;
  z-index: 2;

}

.animated-join-now-btn:before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(360deg, #14606B  6%,  #ACFFE1 100%);
  transform: translate3d(0px, 0px, 0) scale(.95);
  opacity: var(1);
  border-radius: inherit;
  animation: online-join .8s alternate ease-out;
  animation-iteration-count: 8
}

/* 
* Prevents issues when the parent creates a 
* stacking context. (For example, using the transform
* property )
*/
.animated-join-now-btn::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  border-radius: inherit;
}


.animated-online-indicator {
  position: relative;
  z-index: 2;
  display:flex;
  align-items: center;

}


.animated-online-indicator:before {
  content: "";
  z-index: -1;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(-5deg, #09e172 -29%, #4ca581 133% );

  transform: translate3d(0px, 0px, 0) scale(1);
  filter: blur(1px);
  opacity: 0;
  border-radius: 100vh;
  animation: online-indicator .8s alternate ease-out;
  animation-iteration-count: 8;
  width: 8px;
  height: 8px;
}

/* 
* Prevents issues when the parent creates a 
* stacking context. (For example, using the transform
* property )
*/
.animated-online-indicator::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  border-radius: inherit;
}
      


@keyframes online-indicator {
  from {
    transform: scale(.9);
    opacity: .8;

  }

  to {
   transform: scale(1.4);
   opacity: .9;
  }
}